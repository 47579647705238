
.hero-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    width: 70%;
    margin: auto;
    align-items: center;
    margin-top: 30px;
  }
  
  .hero-box {
    flex: 1;
    padding: 20px;
  }
  
  .hero-text {
    text-align: left;
  }
  
  .hero-text h1 {
    font-size: 2rem;
    margin-bottom: 10px;
    color:#34495E

  }
  
  .hero-text p {
    font-size: 1rem;
    margin-bottom: 20px;
    color:#34495E

  }
  
  .hero-text button {
    padding: 10px 20px;
    font-size: 1rem;
    border: none;
    background-color: #354c99;
    color: white;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .hero-image img {
    width: 100%;
    height: auto;
    max-width: 500px; 
  }

  

  @media (max-width: 768px) {
    .hero-section {
      flex-direction: column-reverse;
      text-align: center;
    }
  
    .hero-image {
      order: 2;
    }
  
    .hero-text {
      order: 1;
    }
  
    .hero-text button {
      display: block;
      margin: 0 auto;
    }
  }
  
  .typewriter {
    display: inline-block;
    overflow: hidden; 
    white-space: nowrap;
    border-right: .15em solid #354c99; 
    animation: typing 4s steps(30, end), blink-caret .75s step-end infinite;
    color: #354c99;
  }
  

  @keyframes typing {
    from { width: 0; }
    to { width: 100%; }
  }
  

  @keyframes blink-caret {
    from, to { border-color: transparent; }
    50% { border-color: orange; }
  }
  
 
  @media (max-width: 768px) {
    .typewriter {
      font-size: 1.5rem; 
      animation: typing 3s steps(20, end), blink-caret .75s step-end infinite; 
    }
  }
  
  @media (max-width: 480px) {
    .typewriter {
      font-size: 1rem; 
      animation: typing 2s steps(15, end), blink-caret .75s step-end infinite; 
    }
  }
  
  .stats-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.0rem; 
    padding-top: 2rem; 
    
    position: relative;
    z-index: 10;
    width: 70%;
    margin: auto;
    align-items: center;
    padding-top: 0px;
  }
  
  .stats-box {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 1rem; 
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 0.5rem; 
    gap: 1rem; 
    width: 100%;
    margin: 0.5rem; 
  }
  
  .stats-image {
    width: 3rem; 
    height: 3rem; 
  }
  
  .stats-text {
    display: flex;
    flex-direction: column;
  }
  
  .stats-title {
    text-align: center;
    font-size: 1.10rem; 
    font-weight: bold;
    color: #34495E; 
  }
  
  .stats-description {
    font-size: 0.95rem; 
    color: #34495E; 
    font-weight: 700;
    margin: auto
  }
  
  
  
  @media (min-width: 768px) {
    .stats-container {
      flex-direction: row;
      flex-wrap: wrap;
      gap: 1.25rem; 
    }
  
    .stats-box {
      width: 12rem; 
    }
  }
  
  @media (min-width: 1024px) {
    .stats-box {
      width: 20%; 
    }

    .hero-section {
      
      width: 80%;
     
  }

  .stats-container {
    width: 80%;
}
  }
  
.login-container {
    height: 100vh;
    display: flex;
  }
  
  .left-section {
    background: url('https://account.ineuron.ai/static/images/background-illustration.png') no-repeat center center;
    background-size: cover;
    padding: 50px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    border-radius: 40px;
  }
  
  .welcome-box {
    text-align: center;
    background: linear-gradient(140.7deg, hsla(0, 0%, 87.8%, .48) 3.81%, hsla(0, 0%, 100%, .6) 95.46%);
    box-shadow: 0 0 24px -1px rgba(53, 101, 224, .25);
    -webkit-backdrop-filter: blur(260px);
    backdrop-filter: blur(260px);
    border-radius: 10%;
    padding: 55px 55px 100px;
    z-index: 1;
    width:65%
  }
  
  .logo {
    max-width: 150px;
    margin-bottom: 30px;
  }
  
  .right-section {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f8f9fa;
  }
  
  .login-box {
    width: 100%;
    max-width: 400px;
    /* background: white; */
    padding: 30px;
    border-radius: 8px;
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
  }
  
  .google-btn, .apple-btn {
    width: 100%;
  }
  
  .signup-text {
    margin-top: 15px;
    text-align: center;
  }
  .auth-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 12px; /* Increased padding for better appearance */
    font-weight: bold;
    border: 1px solid #454545; /* Adding a thicker border */
    border-radius: 5px; /* Optional: Add rounded corners */
    background-color: #fff; /* Ensure background color is consistent */
  }
  
  .auth-btn:hover {
    background-color: #f8f9fa; /* Subtle hover effect */
    border-color: #999; /* Change border color on hover */
  }
  
  .auth-icon {
    font-size: 20px;
    margin-right: 10px;
  }
  
  .divider {
    display: flex;
    align-items: center;
    margin: 20px 0;
  }
  
  .divider-line {
    flex-grow: 1;
    height: 1px;
    background-color: #ccc;
    border: none;
  }
  
  .divider-text {
    margin: 0 15px;
    color: #999;
  }
  
  .signup-text {
    margin-top: 15px;
    text-align: center;
  }
  
  @media (max-width: 768px) {
    .right-section {
      padding: 20px;
    }
  }
  
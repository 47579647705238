.privacy-policy-container {
    /* padding: 50px; */
    padding-left: 120px; /* Adjust this value based on the layout */
    padding-right: 120px; /* Matching padding on both sides */
    padding-top: 50px;
    background-color: #f9f9f9;
  }
  
  .privacy-section, .terms-section {
    margin-bottom: 40px;
  }
  
  h2 {
    font-size: 2rem;
    margin-bottom: 20px;
    color: #333;
  }
  
  h3 {
    font-size: 1.4rem;
    margin-top: 20px;
    margin-bottom: 10px;
    color: #555;
  }
  
  p, ul {
    font-size: 0.9rem;
    line-height: 1.6;
    color: #444;
    margin-bottom: 20px;
  }
  
  ul {
    padding-left: 20px;
    list-style: disc;
  }
  
  ul li {
    margin-bottom: 10px;
  }
  
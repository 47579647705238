
.faqs-container {
    width: 80%;
    margin: auto;
    align-items: center;
    color:#34495E
}


.css-nt424e-MuiPaper-root-MuiAccordion-root {
    background-color: #fff !important;
    color: rgba(0, 0, 0, 0.87) !important;
    -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
    border-radius: 0px !important;
    box-shadow: none !important;
    background-image: none !important;
    position: relative;
    -webkit-transition: margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    overflow-anchor: none;
    border-radius: 0;
}
/* #panel1-header{
    color:#393B43
} */
.header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin: auto;
    align-items: center;
    justify-content: space-between;
    width: 80%;
  }
  
  .title {
    font-size: 22px;
    line-height: 32px;
    font-weight: bold;
    text-align: center;
    color: #34495E; 
    width: 100%;
  }
  .title p {
    color:#34495E
  }
  
  .view-all-button {
    opacity: 100;
    padding: 18px;
    font-size: 14px;
    font-weight: 600;
    border-radius: 6px; 
    display: none;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: #ffffff; 
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); 
    color: #4b5563; 
    transition: background-color 0.3s ease;
  }
  
  .view-all-button:hover {
    background-color: #ffffff; 
  }

  .arrow-icon {
    margin-left: 8px; 
    font-size: 16px; 
  }
  

  @media (min-width: 768px) {
    .title {
      font-size: 32px;
      line-height: 40px;
      text-align: left;
      width: auto;
    }
    
    .view-all-button {
      display: flex;
      padding: 10px 18px;
      font-size: 12px;
      padding: 10px 20px; 
      line-height: 20px;
    }
  }
  

  .course-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .course-box {
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    width: calc(33% - 20px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .course-image {
    width: 100%;
    height: auto;
    border-radius: 8px;
  }
  
  .course-heading {
    font-size: 1.5em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 10px;
    color:#34495E
  }
  
  .share-icon {
    margin-left: 10px;
    border-radius: 50%;
    background-color: #c4c9d1;
    color: #0c2b56;
  }
  
  .course-paragraphs {
    margin: 20px 0;
    padding: 0px 10px;
    color:#34495E
  }
  
  .course-paragraph {
    display: flex;
    align-items: center;
    margin: 10px 0;
    padding: 0px 10px;
    color:#34495E
  }
  
  .paragraph-icon {
    margin-right: 10px;
  }
  
  .course-price-info {
    margin: 20px 0;
    padding: 0px 10px;
    display: flex;
  }

  .price-strikethrough {
    margin: 0px 0;
    text-decoration: line-through;
   
  }
  
  .price,
  .price-strikethrough,
  .discount {
    margin: 5px 0;
    padding: 0px 10px;
  }
  
  .discount-icon {
    color: green;
  }
  .price-icon,
  .discount-icon {
    margin-right: 5px;
  }
  
  .course-button {
    padding: 10px 20px;
    background-color: #f4f4f4;
    color: #34495E;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    /* width: 100%; */
    width: 50%;
    /* height: 50%; */
  }
  .course-button-2 {
    padding: 10px 20px;
    background-color: #f4f4f4;
    color: #34495E;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    /* width: 100%; */
    width: 50%;
    /* height: 50%; */
  }
  .course-button-enroll-1 {
    padding: 10px 20px;
    background-color: #354c99;
    color:white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    /* width: 100%; */
    width: 50%;
    /* height: 35%; */
    /* margin-left: 10px; */
  }
  .course-button-enroll-2 {
    padding: 10px 20px;
    background-color: #354c99;
    color:white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    /* width: 100%; */
    width: 50%;
    /* height: 35%; */
    /* margin-left: 10px; */
  }
  .course-button-enroll-2a{
    padding: 10px 20px;
    background-color: #354c99;
    color:white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    /* width: 100%; */
    width: 40%;
    text-align: center;
    text-decoration: none;
    /* height: 35%; */
    /* margin-left: 10px; */

  }
  .course-button-enroll-2-a{
    padding: 10px 20px;
    background-color: #354c99;
    color:white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    /* width: 100%; */
    width: 50%;
    text-align: center;
    text-decoration: none;
    /* height: 35%; */
    /* margin-left: 10px; */

  }
  @media (max-width: 768px) {
    .course-box {
      width: 90%;
      margin: auto;
      margin-bottom: 8px;
  }
  
    .course-container {
      display: block;
    }

    .css-19kzrtu {
      padding: 10px 0px !important;
      
  }

  .price, .price-strikethrough, .discount {
    margin: 5px 0;
    padding: 0px 0px;
    font-size: 0.8rem;
}

.css-heg063-MuiTabs-flexContainer {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin: auto;
  align-items: center;
  justify-content: center;
}

  }
  
 
  
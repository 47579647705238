.drawer {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.3);
  transform: translateX(100%);
  transition: transform 0.3s ease;
  z-index: 9999999999;
}


.drawer.open {
  transform: translateX(0);
}

.drawer-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.navbar-login-btn2 {
  opacity: 1;
  background-color: #354c99;
  color: #ffffff;
  transition: background-color 0.3s;
  font-size: 14px;
  padding: 10px 12px;
  border-radius: 6px;
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-weight: 600;
  margin: auto;
  margin-top: 30px;
  margin-bottom: 30px;
}

.drawer-close {
  background: transparent;
  color: #233C7B;
  border: none;
  font-size: 24px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.drawer-toggle {
  position: absolute;
  top: 10px;
  left: -50px;
  width: 50px;
  height: 50px;
  color: #000;
  border: none;
  font-size: 24px;
  cursor: pointer;
  background: transparent;
}

.search-icon-drawer {
  position: absolute;
  top: 15px;
  right: 800px;
  width: 50px;
  height: 50px;
  color: #000;
  border: none;
  font-size: 24px;
  cursor: pointer;
  background: transparent;
}

.drawer-nav {
  display: flex;
  flex-direction: column;
  padding: 30px 20px 20px;
}

.drawer-nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: left;
}

.drawer-nav li {
  margin: 15px 0;
}

.drawer-nav a {
  text-decoration: none;
  color: #233C7B;
  font-size: 18px;
}

.drawer-nav a:hover {
  text-decoration: underline;
}

@media (min-width: 834px) {
  .drawer-toggle {
    display: none;
  }

  .search-icon-drawer {
    display: none;
  }
}

/* Tablets (Portrait and Landscape) */
@media only screen and (min-width: 320px) and (max-width: 400px) {
  .search-icon-drawer {
    top: 15px;
    right: 355px;
    
}
}

/* Small Mobile Devices (Portrait) */
@media only screen and (min-width: 425px) and (max-width: 480px) {
  .search-icon-drawer {
    top: 15px;
    right: 470px;
    width: 50px;
    height: 50px;
    
}
}
@media only screen and (min-width: 375px) and (max-width: 400px) {
  .search-icon-drawer {
      top: 15px;
      right: 420px;
  }
}
/* @media only screen and (min-width: 320px) and (max-width: 768px) {
 .drawer{
  position: absolute;
 }
} */



.about-us-section, .mission-section {
    padding: 50px;
    text-align: center;
  }
  
  .section-title {
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: #333;
  }
  
  .about-description, .mission-description {
    font-size: 1.2rem;
    line-height: 1.6;
    color: #555;
    margin-bottom: 20px;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .team-image, .mission-image {
    width: 100%;
    max-width: 800px;
    border-radius: 10px;
    margin-top: 20px;
  }
  
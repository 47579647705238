/* Container styles */
.coming-soon-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: linear-gradient(135deg, #1e3c72 0%, #2a5298 100%);
    color: white;
    text-align: center;
    font-family: 'Arial', sans-serif;
    position: relative;
    overflow: hidden;
  }
  
  /* Title styles */
  .coming-soon-title {
    font-size: 4rem;
    font-weight: bold;
    margin: 0;
    animation: fadeIn 2s ease-in-out;
  }
  
  /* Subtitle styles */
  .coming-soon-subtitle {
    font-size: 1.5rem;
    margin-top: 10px;
    opacity: 0.8;
    animation: fadeIn 3s ease-in-out;
  }
  
  /* Background animation */
  .coming-soon-container::before {
    content: '';
    position: absolute;
    top: -10%;
    left: -10%;
    width: 120%;
    height: 120%;
    background: radial-gradient(circle, rgba(255, 255, 255, 0.1) 10%, transparent 60%);
    animation: rotate 15s linear infinite;
    z-index: 0;
  }
  
  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  /* Fade-in animation */
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  